import styled from "@emotion/styled";
import { fontRobotoCondensed, theme } from "@product/scmp-sdk";
import { Swiper, SwiperSlide } from "swiper/react";

import { BaseLink } from "scmp-app/components/common/base-link";
import { ContentItemHomeSecondary } from "scmp-app/components/content/content-item-render/variants/home-secondary";
import {
  ActionBar,
  CoverImage,
  Headline,
  StyledCoverEntityLink,
  Summary,
} from "scmp-app/components/content/content-item-render/variants/home-secondary/styles";

export const StyledContentItemHomeSecondary = styled(ContentItemHomeSecondary)`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${StyledCoverEntityLink} {
    display: block;

    margin-block-start: auto;
  }

  ${ActionBar} {
    margin-block-end: 18px;

    ${theme.breakpoints.up("tablet")} {
      display: none;
    }

    ${theme.breakpoints.up("desktop")} {
      display: block;
    }
  }

  ${CoverImage} {
    inline-size: 100%;
    block-size: 100%;
  }

  ${Headline} {
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
  }

  ${Summary} {
    display: block;

    ${theme.breakpoints.up("tablet")} {
      display: none;
    }

    ${theme.breakpoints.up("desktop")} {
      display: block;
    }
  }
`;

export const Title = styled(BaseLink)`
  margin-block-end: 16px;

  color: #00000080;
  font-size: 20px;
  font-family: ${fontRobotoCondensed};
  text-transform: uppercase;

  ${theme.breakpoints.up("tablet")} {
    margin-block-end: 20px;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block-end: 16px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const ContentItemsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${theme.breakpoints.up("desktop")} {
    grid-template-columns: 1fr;
  }
`;

export const AsiaContainer = styled.div`
  margin-block-start: 20px;
  padding-block-start: 20px;
  border-block-start: 1px rgba(0, 0, 0, 0.1) solid;

  ${props => props.theme.breakpoints.up("tablet")} {
    margin-block-start: 32px;
    padding-block-start: 32px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    margin-block-start: 0;
    padding-block-start: 0;
    border-block-start: none;
  }
`;

export const Container = styled.div`
  padding-block: 24px;
  padding-inline: 20px;

  border-radius: 8px;

  outline: 1px solid #00000033;

  ${StyledContentItemHomeSecondary} {
    border-block-end: 1px solid #0000001a;

    padding-block-end: 18px;
    ${theme.breakpoints.up("tablet")} {
      padding-block-end: 16px;
    }
  }

  /* ${StyledContentItemHomeSecondary}:not(:last-child) {
    border-block-end: 1px solid #0000001a;

    padding-block-end: 18px;

    ${theme.breakpoints.up("tablet")} {
      padding-block-end: 16px;
    }

    ${theme.breakpoints.up("desktop")} {
      padding-block-end: 18px;
      border-block-end: 1px solid #0000001a;
    }
  } */
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${theme.breakpoints.up("tablet")} {
    flex-wrap: wrap;
  }

  ${theme.breakpoints.up("desktop")} {
    flex-wrap: nowrap;
  }

  ${StyledContentItemHomeSecondary} {
    ${theme.breakpoints.up("tablet")} {
      &:nth-child(1),
      &:nth-child(2) {
        flex-basis: calc(50% - 20px);
      }
    }
    ${theme.breakpoints.up("desktop")} {
      flex-direction: column;
      &:nth-child(1),
      &:nth-child(2) {
        flex-basis: unset;
      }
    }
  }

  ${theme.breakpoints.up("tablet")} {
    flex-direction: row;
    gap: 16px 20px;
  }

  ${theme.breakpoints.up("desktop")} {
    flex-direction: column;
    gap: 16px;
  }
`;

export const MoreLinkContainer = styled.div`
  margin-block-start: 24px;

  ${theme.breakpoints.up("tablet")} {
    margin-block-start: 20px;
  }

  ${theme.breakpoints.up("desktop")} {
    margin-block-start: 16px;
  }
`;

export const MoreLink = styled(BaseLink)`
  padding-block: 7px;
  padding-inline: 8px;

  color: #4585ff;
  font-size: 14px;
  text-transform: uppercase;

  border: 1px solid #4585ff;
  border-radius: 2px;
`;

export const SwiperContainer = styled.div`
  max-inline-size: 100%;

  ${props => props.theme.breakpoints.only("tablet")} {
    display: none;
  }
`;

export const StyledSwiper = styled(Swiper)`
  &.swiper {
    padding-block-end: 32px;

    .swiper-pagination {
      inset-block-end: 0;

      .swiper-pagination-bullet {
        margin: 0;

        background-color: #bbbbbb;

        opacity: 0.5;
        &:not(:last-child) {
          margin-inline-end: 8px;
        }
      }

      .swiper-pagination-bullet-active {
        background-color: #000000;

        opacity: 1;
      }
    }
  }
`;

export const StyledSwiperSlide = styled(SwiperSlide)`
  display: flex;
  justify-content: center;
  align-items: center;

  block-size: calc((100% - 16px) / 2) !important;
`;
